
































































































































/* eslint-disable camelcase */
import Vue from 'vue';
import RegisterForm from '@/components/Register.vue';
import { mapGetters } from 'vuex';
import { validateComponent, validationErrorHandler } from '@/validation';
import { Validatable } from '@/validation/types';
import {
  inArray,
  length,
  max, maxLength, min, required,
} from '@/validation/rules';

export default Vue.extend({
  components: {
    RegisterForm,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    isPlus: Boolean,

    requestBudget: {
      type: Number,
      required: false,
      default: null,
    },
    requestBudgetCurrency: {
      type: String,
      required: false,
      default: 'EUR',
    },
    requestDeadline: {
      type: Date,
      required: false,
      default: null,
    },
    requestReferrer: {
      type: [String, Array],
      required: false,
      default: null,
    },
    requestSummary: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    const deadline = new Date();
    deadline.setHours(deadline.getHours() + 1, 0, 0, 0); // Next full hour

    return {
      error: null as Error | null,

      form: {
        summary: '',
        budget: 0 as number,
        deadline,
      },
      formOptions: {
        budget: !!this.requestBudget,
        deadline: !!this.requestDeadline,
      },

      loading: false,

      showRegisterForm: false,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'account/IsLoggedIn',
    }),
    deadlineMax(): Date {
      const d = new Date();

      d.setFullYear(d.getFullYear() + 10);
      d.setHours(d.getHours() + 2, 0, 0, 0);

      return d;
    },
    deadlineMin(): Date {
      const d = new Date();

      d.setHours(d.getHours() + 1, 0, 0, 0); // Next full hour

      return d;
    },
    referrer(): string | null {
      if (this.requestReferrer) return this.requestReferrer.toString().substr(0, 255);

      // document.referrer may be empty string
      if (!document.referrer) return null;

      const referrer = (new URL(document.referrer)).origin;

      // May be page itself
      if (referrer.includes(process.env.VUE_APP_BASE_URL || window.location.hostname)) return null;

      return referrer.substr(0, 255);
    },
  },
  watch: {
    requestSummary: {
      immediate: true,
      handler(summary) {
        if (summary) this.form.summary = summary;
      },
    },
  },
  methods: {
    onLoggedIn(): void {
      this.showRegisterForm = false;
      this.submit();
    },
    check(): boolean {
      const summary = (): boolean => validateComponent(
        this.$refs.summary as Validatable,
        this.form.summary, [
          required(this.$t('V.summary.notOptional')),
          maxLength(2000, this.$t('V.summary.length')),
        ],
      );

      const budget = (): boolean => validateComponent(
        this.$refs.budget as Validatable,
        this.requestBudgetCurrency, [
          required(this.$t('V.budget_currency.notOptional')),
          length(3, 3, this.$t('V.budget_currency.length')),
          // budget_currency.currencyCode
          inArray(['EUR'], this.$t('V.budget_currency.in')),
        ],
      ) && validateComponent(
        this.$refs.budget as Validatable,
        this.form.budget, [
          required(this.$t('V.budget_amount.notOptional')),
          min(0, this.$t('V.budget_amount.min')),
          max(9_999_999_99, this.$t('V.budget_amount.max')),
        ],
      );

      const deadline = (): boolean => validateComponent(
        this.$refs.deadline as Validatable,
        this.form.deadline, [
          required(this.$t('V.deadline.notOptional')),
        ],
      );

      return summary()
        && (this.formOptions.budget ? budget() : true)
        && (this.formOptions.deadline ? deadline() : true);
    },
    async submit(): Promise<void> {
      if (!this.check()) return;

      if (!this.isAuthenticated) {
        this.showRegisterForm = true;
        return;
      }

      this.loading = true;
      this.error = null;

      const data = await this.$store.dispatch('account/CreateCommission', {
        form: {
          business_id: this.businessId,
          summary: this.form.summary,

          referrer: this.referrer,

          budget_amount: this.formOptions.budget
            ? Math.round(this.form.budget * 100) : undefined,
          budget_currency: this.formOptions.budget
            ? this.requestBudgetCurrency.toUpperCase() : undefined,
          deadline: this.formOptions.deadline
            ? this.form.deadline.toISOString() : undefined,
        },
      }).catch(validationErrorHandler({
        summary: this.$refs.summary as Validatable,
        budget_amount: this.$refs.budget as Validatable,
        // budget_currency: this.$refs.budget as Validatable,
        deadline: this.$refs.deadline as Validatable,
      }, (m) => this.$t(m))).catch((e) => { this.error = e; });

      this.loading = false;
      if (!data) return;

      this.$emit('commission-requested', data.data);
      this.$a.goal(this.$a.goals.CommissionRequestSubmit);
    },
  },
});
